.block--signup-form h2 {
  font-size: 17px;
}

.block--signup-form label {
  display: block;
  margin-top: 10px;
  font-size: 1rem;
}

.block--signup-form input {
  width: 100%;
  border: none;
  box-sizing: border-box; 
  font-size: 1.5em;

  padding: 5px;
}

.block--signup-form input[type="submit"] {
  font-size: 1.5rem;
  margin-top: 15px;
  border: 2px solid white;
  background: #f9dfea;
}