header {
  background: #f9dfea;
}

.header {
  max-width: 80rem;
  padding: 0.5rem 0;
  margin: auto;
  position: relative;
  display: flex;
}

@media (max-width: 800px) {
  .header {       
    margin: 0 1rem;
  }
}

.logo {
  background: url("./logo.png") no-repeat;
  background-position: left center;
  text-indent: -9999px;
  height: 5rem;
  width: 12rem;
  margin-top: 1rem;
  background-size: contain;
}

.links {
  line-height: 7rem;
}

.links.links--left {
  margin-left: 15px;
}

.links.links--right {
  flex: 100 1 auto;
  text-align: right;
}

.links a.icon {
  font-size: 1.2em;
  padding: 0 5px;
}

.links a {
  color: #33232a;
  font-size: 17px;
  padding: 0 8px;
  text-decoration: none;
}

.links a:not(.icon):hover {
  text-decoration: underline;
}

@media (max-width: 400px) {
  .header {       
    flex-direction: column;
  }
  .links {
    line-height: 5rem;
  }
  .logo {
    width: 100%;
    background-position: center center;
  }
  .links.links--left, .links.links--right {
    text-align: center;
  }
}